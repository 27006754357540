import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ListMenu from '@rio-cloud/rio-uikit/lib/es/ListMenu';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { ServiceRoles } from '@helpers/constants.jsx';

export default observer((props) => {
    const { serviceStore, authStore, uiStore } = useStores();
    const { t } = useTranslation();

    useEffect(() => {
        uiStore.setShowSidebar(uiStore.showSidebar);
    }, [uiStore]);

    const canTemplateCRUD =
      authStore.roles.includes(ServiceRoles.RemoteMessageAgentGlobal) ||
      authStore.roles.includes(ServiceRoles.RemoteMessageCountryManagerGlobal) ||
      authStore.roles.includes(ServiceRoles.RemoteMessageTester);

    const menuItems =
      serviceStore.currentService === Services.OnlineTraffic ?
        [
          {
            group: `${t("fotaone.ot.activationData.activation.many")}`,
            navItems: [
              {
                key: '1',
                item: <NavLink to={`${serviceStore.currentService}/vehicles`}>
                  {`${t("fotaone.general.vehicleData.vehicle.many")}`}
                </NavLink>,
              },
              {
                key: '2',
                item: <NavLink to={`${serviceStore.currentService}/analytics`}>
                  {`${t("fotaone.ot.analyticData.analytics")}`}
                </NavLink>,
              },
            ],
          },
        ] :
      serviceStore.currentService === Services.MapUpdate ?
        [
          {
            group: `${t("fotaone.general.vehicleData.vehicle.many")}`,
            navItems: [
              {
                key: '1',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/vehicles`}
                    data-testid='vehicles-overview'
                  >
                    {`${t("fotaone.general.overview")}`}
                  </NavLink>
                ),
              },
              {
                key: '2',
                item: <NavLink to={`${serviceStore.currentService}/groups`}>
                  {`${t("fotaone.mu.groupData.group.many")}`}
                </NavLink>,
              },
              {
                key: '3',
                item: <NavLink to={`${serviceStore.currentService}/installed-maps`}>
                  {`${t("fotaone.mu.installedMapsPage.installedMaps")}`}
                </NavLink>,
              },
            ],
          },
          {
            group: `${t("fotaone.mu.fileData.map.many")}`,
            navItems: [
              {
                key: '1',
                item: <NavLink to={`${serviceStore.currentService}/files`}>
                  {`${t("fotaone.mu.fileData.file.many")}`}
                </NavLink>,
              },
              {
                key: '2',
                item: <NavLink to={`${serviceStore.currentService}/archive`}>
                  {`${t("fotaone.mu.fileData.archive")}`}
                </NavLink>,
              },
            ],
          },
          {
            group: `${t("fotaone.mu.rolloutData.rollout.many")}`,
            navItems: [
              {
                key: '1',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/rollouts`}
                    data-testid='rollouts-overview'
                  >
                    {`${t("fotaone.general.overview")}`}
                  </NavLink>
                ),
              },
              {
                key: '2',
                item: <NavLink to={`${serviceStore.currentService}/campaigns`}>
                  {`${t("fotaone.mu.campaignData.campaign.many")}`}
                </NavLink>,
              },
            ],
          },
        ] :
      serviceStore.currentService === Services.FeatureDashboard ?
        [
          {
            group: `${t("fotaone.fd.serviceData.digitalServices")}`,
            navItems: [
              {
                key: '1',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/vehicles`}
                  >
                    {`${t("fotaone.general.vehicleData.vehicle.many")}`}
                  </NavLink>
                ),
              },
              {
                key: '2',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/services`}
                  >
                    {`${t("fotaone.fd.serviceData.service.many")}`}
                  </NavLink>
                ),
              },
            ],
          },
        ] :
      serviceStore.currentService === Services.RemoteMessage ?
        [
          {
            group: `${t("fotaone.general.vehicleData.vehicle.many")}`,
            navItems: [
              {
                key: '1',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/vehicles`}
                  >
                    {`${t("fotaone.general.overview")}`}
                  </NavLink>
                ),
              },
            ],
          },
          {
            group: `${t("fotaone.rm.messageData.message.many")}`,
            navItems: [
              {
                key: '1',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/messages`}
                  >
                    {`${t("fotaone.general.overview")}`}
                  </NavLink>
                ),
              } ,
              {
                key: '2',
                item: (
                  <NavLink
                    to={`${serviceStore.currentService}/templates`}
                  >
                    {`${t("fotaone.rm.templateData.template.many")}`}
                  </NavLink>
                ),
              } ,
            ],
          },
          ...(canTemplateCRUD ?
            [
              {
                group: `${t("fotaone.rm.messageData.myMessages")}`,
                navItems: [
                  {
                    key: '1',
                    item: (
                      <NavLink to={`${serviceStore.currentService}/myMessages`}>
                        {`${t("fotaone.general.overview")}`}
                      </NavLink>
                    ),
                  },
                  {
                    key: '2',
                    item: (
                      <NavLink to={`${serviceStore.currentService}/toApprove`}>
                        {`${t("fotaone.rm.messageData.toApprove")}`}
                      </NavLink>
                    ),
                  },
                ],
              },
            ] : []
          ),
        ] : null;

    return (
        <div className='flex height-100pct display-flex flex-wrap overflow-auto'>
            <div className='sidebar-navigation bg-black height-100pct width-50 position-relative'>
                <div className='btn-toolbar'>
                    <button
                        type='button'
                        className='btn btn-link width-50 height-50'
                        onClick={() => uiStore.setShowSidebar(!uiStore.showSidebar)}
                    >
                        <span
                            className='rioglyph text-color-white text-size-h2 margin-0 rioglyph-menu-hamburger'
                            aria-hidden='true'
                        />
                        <span className='position-absolute left-25 top-15'>
                            {uiStore.showSidebar && (
                                <span
                                    className='rioglyph rioglyph-triangle-left text-color-white text-size-2 position-absolute padding-1 left-10'
                                />
                            )}
                        </span>
                    </button>
                </div>
            </div>
            <Sidebar
                width={180}
                title={`${t("fotaone.general.menu")}`}
                closed={!uiStore.showSidebar}
                onClose={() => uiStore.setShowSidebar(false)}
            >
                <ListMenu menuItems={menuItems} />
            </Sidebar>
        </div>
    );
});
