import { observable, action, computed, makeObservable } from 'mobx';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import config from '@src/config';
import { INIT_URL_LINK, ServiceRoles } from '@helpers/constants';
import { Services } from './service';

let instance; // singleton instance
export class AuthStore {
    manager = null;
    user = null;
    roles = [];
    constructor() {
        makeObservable(this, {
            isLoggedIn: computed,
            manager: observable,
            user: observable,
            roles: observable,
            init: action,
            login: action,
            renew: action,
            completeLogin: action,
            completeRenew: action,
            completeLogout: action,
            handleUserLoaded: action,
            handleUserUnloaded: action,
            logout: action,
            handleError: action,
            hasWriteAccess: action,
        });

        this.login = this.login.bind(this);
        this.completeLogin = this.completeLogin.bind(this);
        this.logout = this.logout.bind(this);
        this.completeLogout = this.completeLogout.bind(this);
        this.handleUserLoaded = this.handleUserLoaded.bind(this);
        this.handleUserUnloaded = this.handleUserUnloaded.bind(this);
        this.hasWriteAccess = this.hasWriteAccess.bind(this);
    }
    get isLoggedIn() {
        return this.user && !this.user.expired;
    }

    async init() {
        this.manager = new UserManager({
            authority: config.AUTH_ISSUER,
            client_id: config.AUTH_CLIENT_ID,
            redirect_uri: `${config.BASEURL}/auth/handle`,
            silent_redirect_uri: `${config.BASEURL}/auth/renew`,
            automaticSilentRenew: true,
            response_type: 'code',
            scope: 'openid',
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        });
        this.manager.events.addUserLoaded(this.handleUserLoaded);
        this.manager.events.addUserUnloaded(this.handleUserUnloaded);

        const user = await this.manager.getUser();
        if (user) this.handleUserLoaded(user);
    }

    login() {
        return this.manager.signinRedirect().catch((error) => this.handleError(error));
    }

    renew() {
        return this.manager
            .signinSilent()
            .then(this.manager.storeUser.bind(this.manager))
            .catch((error) => this.handleError(error));
    }

    completeLogin() {
        return this.manager.signinRedirectCallback().catch(this.handleError);
    }

    completeRenew() {
        return this.manager.signinSilentCallback().catch(this.handleError);
    }

    completeLogout() {
        this.manager.signoutRedirectCallback().catch(this.handleError);
        this.manager.removeUser();
    }

    handleUserLoaded(user) {
        this.user = user;
        if (this.isLoggedIn) {
            this.roles = user.profile['cognito:groups'];

            localStorage.setItem('Authorization', `Bearer ${user['id_token']}`);
            this.manager.startSilentRenew();
        } else {
            this.logout();
        }
        this.manager.clearStaleState();
    }

    handleUserUnloaded() {
        this.user = null;
        this.roles = [];
    }

    logout() {
        // FIX - Logout behavior where the user is given an error message claiming a missing client_id after being redirected to <cognito-domain>/login
        // Cognito logout behaviour requires to either:
        // 1- provide a logout_uri and a client_id on the request, specify on Cognito settings an allowed Sign-Out URL that matches the provided logout_uri -> redirect the request to that login_uri
        // 2 - do not specify anything -> redirect the request to <cognito-domain>/login
        // https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
        // This has worked in the past without any query params.
        // This should be just a workaround since the library used for OIDC is, at this time, 3 years old and unmaintained.
        this.manager.settings. extraQueryParams = {logout_uri: `${config.BASEURL}/auth/logout`, client_id: config.AUTH_CLIENT_ID}
        return this.manager.signoutRedirect();
    }

    hasRole(...roles) {
        for (const role of roles) {
            if (this.roles.includes(role)) return true;
        }

        return false;
    }

    hasWriteAccess(service) {
        switch (service) {
            case Services.MapUpdate:
                return this.hasRole(ServiceRoles.MapUpdate);
            case Services.OnlineTraffic:
                return this.hasRole(ServiceRoles.OnlineTraffic);
            case Services.FeatureDashboard:
                return this.hasRole(ServiceRoles.FeatureDashboard);
            default:
                return false;
        }
    }

    hasAccess(service) {
        switch (service) {
            case Services.MapUpdate:
                return this.hasRole(ServiceRoles.MapUpdate, ServiceRoles.MapUpdateRead);
            case Services.OnlineTraffic:
                return this.hasRole(ServiceRoles.OnlineTraffic, ServiceRoles.OnlineTrafficRead);
            case Services.FeatureDashboard:
                return this.hasRole(ServiceRoles.FeatureDashboard, ServiceRoles.FeatureDashboardRead);
            case Services.RemoteMessage:
                return this.hasRole(
                  ServiceRoles.RemoteMessageRead,
                  ServiceRoles.RemoteMessageAgentGlobal,
                  ServiceRoles.RemoteMessageCountryManagerGlobal,
                  ServiceRoles.RemoteMessageTester,
                  ServiceRoles.RemoteMessageTechUserTi,
                  ServiceRoles.RemoteMessageTechUserRecall,
                  ServiceRoles.RemoteMessageTechUserServiceInfo,
                )
            default:
                return this.isLoggedIn;
        }
    }

    getRootURIForUser() {
        if (!this.isLoggedIn) {
            return '/auth';
        }

        if (sessionStorage.getItem(INIT_URL_LINK) !== null) {
            const redirectLink = sessionStorage.getItem(INIT_URL_LINK);
            // Timeout is necessary because React runs the code multiple times, so we have no control over when the deletion happened.
            setTimeout(() => sessionStorage.removeItem(INIT_URL_LINK), 7000);
            return redirectLink;
        }

        if (this.hasAccess(Services.MapUpdate)) {
            return '/maps';
        }
        if (this.hasAccess(Services.OnlineTraffic)) {
            return '/online-traffic';
        }
        if (this.hasAccess(Services.FeatureDashboard)) {
            return '/feature-dashboard';
        }
        if (this.hasAccess(Services.RemoteMessage)) {
            return '/remote-message';
        }
        return '/unauthorized'; // no valid access
    }

    handleError(error) {
        console.error('Auth Error', error);
    }

    static instance() {
        if (!instance) {
            instance = new AuthStore();
        }
        return instance;
    }
}
