import React, { useCallback, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import TargetSelection from './common/TargetSelection';
import StepLayout from './common/StepLayout';
import Table from '@common/Table/Table';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { mapRolloutDialogFileTable, rolloutLabels } from '../mapupdate/mapupdateHelper/constants.jsx';
import { useTranslation } from 'react-i18next';
import { element } from 'prop-types';

const Overview = observer((props) => {
    const { serviceStore } = useStores();
    const [warningLabel, setWarningLabel] = useState('');
    const { t } = useTranslation();
    const { data } = props;
    const transformedData = [data];
    const fileState = data.approval_state;
    let testVehicle = false;
    if (data.vehicle === true) {
        testVehicle = data.testfleet[0];
    }
    if (!warningLabel && testVehicle === false && fileState === 'UNRELEASED') {
        setWarningLabel(`${t("fotaone.mu.rolloutData.unreleasedFileWarning")}`);
    }

    const render = () => {
        return (
            <StepLayout title={`${t("fotaone.mu.fileData.file.one")}`}>
                {data.approvalState === 'UNRELEASED' ? (
                    warningLabel === '' ? (
                        ''
                    ) : (
                        <div className='text-color-warning warning-wrapper'>
                            <span className='rioglyph rioglyph-warning-sign warning-sign' />
                            {warningLabel}
                        </div>
                    )
                ) : (
                    ''
                )}
                <Table
                    data={transformedData || []}
                    columnDetails={{}}
                    columns={mapRolloutDialogFileTable.filter((value) => data[value.key])}
                />
            </StepLayout>
        );
    };
    return render();
});

export default observer(() => {
    const { filesStore, uiStore, mapRolloutStore } = useStores();
    const [step, setStep] = useState(0);
    const { t } = useTranslation()
    const labelsProgressBar = rolloutLabels;
    const steps = [Overview, TargetSelection, Overview];
    labelsProgressBar.forEach((element) => {
        element.label = `${t(element.label)}`;
    });

    const { query, sortBy, sortDir, limit, offset } = '';
    const searchFiles = useCallback(
        debounce((params) => filesStore.getFilesGraphql(params), 200),
        [],
    );

    const onUpdateData = (d) => {
        mapRolloutStore.setRolloutData(d);
    };

    const onHide = () => {
        setStep(0);
        uiStore.setShowRolloutDialog(false);
        mapRolloutStore.setDefaultRolloutData();
    };

    const onNextStep = () => {
        let update_step = step + 1;
        if (update_step > steps.length) {
            update_step = steps.length;
        }
        setStep(update_step);
    };

    const onPreviousStep = () => {
        let update_step = step - 1;
        if (update_step < 0) {
            update_step = 0;
        }
        setStep(update_step);
    };

    const onSelectedChanged = (s) => {
        setStep(s);
    };

    const onFinish = () => {
        mapRolloutStore.createRollout({ isMultipleRollout: false });
        onHide();
        searchFiles({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
        });
    };

    const render = () => {
        const prev_button = (
            <button type='button' className='btn btn-primary' onClick={onPreviousStep}>
                {`${t("fotaone.general.previous")}`}
            </button>
        );

        let next_button_disabled = false;
        if (step === 1 && mapRolloutStore.rolloutDialogData.vehicle === null) {
            next_button_disabled = true;
        }

        const next_button = (
            <button type='button' className='btn btn-primary' onClick={onNextStep} disabled={next_button_disabled}>
                {`${t("fotaone.general.next")}`}
            </button>
        );
        const finish_button = (
            <button type='button' className='btn btn-primary' onClick={onFinish}>
                {`${t("fotaone.mu.rolloutData.startRollout")}`}
            </button>
        );

        const footer = (
            <div className='pull-right btn-toolbar'>
                {step > 0 && prev_button}
                {step < steps.length - 1 && next_button}
                {step === steps.length - 1 && finish_button}
            </div>
        );

        const CurrentStep = steps[step];

        const body = (
            <div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <SteppedProgressBar
                            selectedStepNumber={step}
                            onSelectedChanged={onSelectedChanged}
                            labels={labelsProgressBar}
                        />
                    </div>
                </div>
                <div className='row equal-height'>
                    <div className='col-xs-12 col-md-12 margin-bottom-15'>
                        <div className='panel panel-default panel-body height-100pct'>
                            <CurrentStep
                                data={mapRolloutStore.rolloutDialogData}
                                onChange={onUpdateData}
                                className={'table-wrapper'}
                            />
                        </div>
                    </div>
                </div>
                <div className='checkbox'>
                    <label>
                        <input
                            type='checkbox'
                            size='large'
                            defaultChecked={mapRolloutStore.rolloutDialogData.interactiveMode}
                            onChange={(event) =>
                                mapRolloutStore.setRolloutData({ interactiveMode: event.target.checked })
                            }
                        />
                        <span className='checkbox-text'>{`${t("fotaone.mu.rolloutData.startInteractiveMode")}`}</span>
                    </label>
                </div>
            </div>
        );

        return (
            <Dialog
                show={uiStore.showRolloutDialog}
                title={`${t("fotaone.mu.rolloutData.startRollout")}`}
                body={body}
                footer={footer}
                onHide={onHide}
                className='fileUploadForm'
                showCloseButton={true}
            />
        );
    };

    return render();
});
