import React, { useState, useEffect, useCallback } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { cloneObject } from '@helpers/object.js';

import Table from '../../../../Common/Table/Table.jsx';
import Filter from '../../../../Common/Filter/Filter';
import FilterButton from '../../../../Common/Buttons/FilterButton';
import GroupDropdown from './GroupDropdown.jsx';

import { useStores } from '@stores/context.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '../../../../Common/Pagination/Pagination.jsx';
import { assetTableData, muFilterDataKeys } from '../Vehicle.constants.jsx';
import { SupportedFileTypes } from '@stores/exports.js';
import { removeParamFromIrl, setQueryParamToUrl } from '../../../mapupdateHelper/utils.jsx';
import SimpleTooltip from '@rio-cloud/rio-uikit/lib/es/SimpleTooltip';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import Notification from  '@rio-cloud/rio-uikit/lib/es/Notification';
import { client as mapUpdateAxiosClient } from '../../../../../../src/api/mapupdate-client.js';
import { useTranslation } from 'react-i18next';

export default observer((props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [showFilter, setShowFilter] = useState(false);
    const { assetStore, serviceStore, exportsStore } = useStores();
    const { paginationStore, filterStore } = props;
    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;
    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const filters = filterStore?.filters;

    const searchAssets = useCallback(
        debounce(async (params) => {
            await assetStore.getAssetsGraphql(params);
            paginationStore.setItemsLength(assetStore.assetTotal);
        }, 200),
        [],
    );
    useEffect(() => {
        const groupNameFilter = new URLSearchParams(location.search).get('group');
        searchAssets({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
            groupNameFilter,
            filters,
        });
    }, [
        query,
        sortBy,
        sortDir,
        limit,
        activePage,
        location.search,
        searchAssets,
        offset,
        filters,
        assetStore.shouldReload,
    ]);

    const rowClicked = (row) => {
        navigate(`${serviceStore.currentService}/vehicles/${row.id}${location.search}`);
    };

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };

    const handleSortChange = (_sortBy, _sortDir) => {
        filterStore.setSortBy(_sortBy);
        filterStore.setSortDir(_sortDir);
    };

    const handleFilterState = (state) => {
        setShowFilter(state);
    };

    const handleFilterConfirmation = (newData) => {
        newData.forEach((item) => {
            if (item.key === muFilterDataKeys.FotaTestFleetFilter) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('tags', item.result.join(','));
                } else {
                    removeParamFromIrl('tags');
                }
            }
            if (item.key === muFilterDataKeys.FotaRegionFilter) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('regions', item.result.join(','));
                } else {
                    removeParamFromIrl('regions');
                }
            }
            if (item.key === muFilterDataKeys.Environment) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('environment', item.result.join(','));
                } else {
                    removeParamFromIrl('environment');
                }
            }
        });
        filterStore.setFilters(cloneObject(newData));
    };

    const handleExportButton = async () => {
        const params = {
            type: SupportedFileTypes.CSV,
            feature: serviceStore.currentService,
            headers: cloneObject(assetTableData.columns),
            query,
            filters,
            sortBy,
            sortDir,
        };
        await exportsStore.exportAsFile(params);
        assetStore.triggerReload();
    };

    const handleRepublishMapStates = async () => {
        await mapUpdateAxiosClient.republishMapStates()
          .then(function(res) {
              if (res.data) {Notification.success(t("fotaone.notification.success.assets.republishMapStates"));} 
          }).catch(function(err) {Notification.error(t("fotaone.notification.error.assets.republishMapStates"));});
    };

    return (
        <div>
            <Filter
                    filters={filterStore.filters}
                    title={`${t("fotaone.mu.filterVehicles")}`}
                    showFilter={showFilter}
                    onHide={() => handleFilterState(false)}
                    onConfirm={(data) => handleFilterConfirmation(data)}
                    id={'mapupdate-filter-modal'}
                    dataTestId={'mapupdate-filter-modal'}
                />
                <Table
                    toolbarElement={
                        <>
                            <GroupDropdown groupId={props.groupId} />
                            <div className='table-toolbar-column table-toolbar-column-spacer'>
                                <SimpleTooltip content={t("fotaone.general.tooltip.republishMapStates")} width='auto' placement='bottom'>
                                    <Button iconOnly className='margin-left-10' onClick={handleRepublishMapStates}>
                                        <span className='rioglyph rioglyph-cloud-upload' />
                                    </Button>
                                </SimpleTooltip>
                            </div>
                            <div className='table-toolbar-column'>
                                <Observer>
                                    {() => (
                                        <SimpleTooltip content={t("fotaone.general.tooltip.exportCsv")} width='auto' placement='bottom'>
                                            <Button
                                                className='btn btn-default btn-icon-only'
                                                onClick={handleExportButton}
                                                id={'export-map-update'}
                                                disabled={exportsStore?.stateMachine?.isLoading}
                                            >
                                                <span
                                                    className={`rioglyph ${exportsStore?.stateMachine?.isLoading ? 'rioglyph-spinner spinning  ' : 'rioglyph-csv'}`}
                                                    style={{ fontSize: '2.3rem' }}
                                                />
                                            </Button>
                                        </SimpleTooltip>
                                    )}
                                </Observer>
                            </div>
                        </>
                    }
                    toolbarElementLeft={
                        <FilterButton id={'button-filter-mapupdate'} onShowFilter={() => handleFilterState(true)} />
                    }
                    onRowClick={rowClicked}
                    rowKey='id'
                    selectedRow={props.selectedRow}
                    showSearch={true}
                    handleSearchValueChange={handleSearchValueChange}
                    handleSortChange={handleSortChange}
                    searchValue={query}
                    data={assetStore.assetList || []}
                    columnDetails={assetTableData.columnDetails}
                    defaultColumnOrder={assetTableData.defaultColumnOrder}
                    columns={assetTableData.columns}
                    columnOrder={assetTableData.columnsOrder}
                    hiddenColumns={assetTableData.hiddenColumns}
                    columnLabels={assetTableData.columnLabels}
                    disabledColumns={assetTableData.disabledColumns}
                    actionColumn='buttons'
                    loading={assetStore.assetsState.isLoading}
                />
                <Pagination paginationStore={paginationStore} />
        </div>
    );
});
