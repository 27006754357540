import React, { useState, useRef, useEffect, useCallback } from 'react';
import omit from 'lodash/fp/omit';
import classNames from 'classnames';
import { get, setWith, debounce, times } from 'lodash';
import { client as mapUpdateGraphqlClient } from '@api/mapupdate-graphql';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import Loader from '@common/Loader/Loader';
import { MAP_FILES_QUERY } from '@stores/files.jsx';
import './FileCheckTable.css';
import { getRegion } from '../mapupdate/mapupdateHelper/region.jsx';
import { mapFileCheckTableData } from '../mapupdate/mapupdateHelper/constants.jsx';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { useTranslation } from 'react-i18next';

export const ACTIVE_CLASS = 'active';
export const DATA_ATTRIBUTE = 'data-key';

const TableRow = React.memo((props) => {
    // memo to avoid rerendering (should component udpate set to false)

    // todo: implement proper pagination into this component
    // todo: reduce size of the component (to about 200 lines of codes or split code into multiple files.
    const { row, columns, colLabels, onClick } = props;
    return (
        <tr key={row.id} data-key={row.id} onClick={onClick}>
            <td className='table-checkbox'>
                <Checkbox />
            </td>
            {columns.map((col) => (
                <td key={col} data-field={colLabels[col]}>
                    {col === 'version_number' ? (
                        <>
                            <span>{get(row, col)}</span>
                            <br />
                            <span
                                className={
                                    row.approval_state === 'RELEASED'
                                        ? 'label label-success label-condensed margin-left-4 label-info'
                                        : 'label label-default label-condensed margin-left-4 label-info'
                                }
                            >
                                {row.approval_state}
                            </span>
                        </>
                    ) : col === 'map_region_enum' ? (
                        <>{getRegion(row.map_region_enum)}</>
                    ) : (
                        <span>{get(row, col)}</span>
                    )}
                </td>
            ))}
        </tr>
    );
});

const FileCheckTable = (props) => {
    const { onChange } = props;
    const { serviceStore } = useStores();
    const { t } = useTranslation();
    
    const sortField = {
        CREATION_TIMESTAMP: 'created_at',
    };

    const columnOrder = mapFileCheckTableData.defaultColumnOrder;
    const hiddenColumns = [];
    const columnDetails = mapFileCheckTableData.columnsDetails;
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [step] = useState(3);
    const [query, setQuery] = useState('');
    const [sortBy, setSortBy] = useState(sortField.CREATION_TIMESTAMP);
    const [sortDir, setSortDir] = useState(SortDirection.DESCENDING);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(props.data.filesList || []);
    const [selectedRowIds, setSelectedRowIds] = useState(props.data.activeIds || []);
    const [loading, setLoading] = useState(false);
    const [lastActiveRowId, setLastActiveRowId] = useState('');
    const [activeRowId, setActiveRowId] = useState('');
    const [dataTotal, setDataTotal] = useState();
    const [size, setSize] = useState(Math.ceil(dataTotal / limit));
    const [active, setActive] = useState(1);
    const tableRef = useRef();
    const hasMounted = useRef();
    const searchParams = {
        limit,
        offset,
        query,
        sortBy,
        sortDir,
    };

    const activeHandler = (page) => {
        setActive(page);
        setOffset((page - 1) * limit);
    };

    const updateDataCallback = () => {
        if (hasMounted.current) {
            onChange({ filesList: selectedData, activeIds: selectedRowIds });
        }
    };

    useEffect(updateDataCallback, [selectedRowIds]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
        } else {
            updateRows();
        }
    });

    const getFiles = useCallback(
        debounce(async (params) => {
            try {
                let data = [];
                let res = null;
                let dataTotal = 0;
                setLoading(true);

                const variables = {
                    query: `%${params.query}%`,
                    limit: params.limit,
                    offset: params.offset,
                };

                if (params.sortBy) {
                    variables.orderBy = {};
                    setWith(variables.orderBy, params.sortBy.split('.'), params.sortDir, Object);
                }

                if (serviceStore.currentService === Services.MapUpdate) {
                    res = await mapUpdateGraphqlClient.query({
                        query: MAP_FILES_QUERY,
                        variables,
                        fetchPolicy: 'no-cache',
                    });
                    const {
                        maps_view,
                        maps_view_aggregate: { aggregate: { count: total } },
                    } = res.data.mu;
                    data = maps_view;
                    dataTotal = total;
                }
                setData(data);
                setDataTotal(dataTotal);
                setSize(Math.ceil(dataTotal / limit));
            } catch (err) {
                Notification.error('Error loading files');
            } finally {
                setLoading(false);
            }
        }, 200),
        [serviceStore],
    );

    useEffect(() => {
        getFiles({ ...searchParams });
    }, [limit, offset, query, sortBy, sortDir, active]);

    const updateRows = () => {
        if (!tableRef.current) {
            return;
        }

        // As the table ref returns an HTMLCollection,
        // we use destructuring to convert it to a normal array.
        const rows = [...tableRef.current.rows];

        // Due to performance reasons the individual rows should not re-render when the
        // selected driver changes, hence we need to avoid passing that information as props
        // to the row component otherwise it would re-render as the props have changed.
        // Instead, we check here which row to highlight.
        if (lastActiveRowId !== activeRowId) {
            removeHighlightFromRow(rows, lastActiveRowId);
            highlightRow(rows, activeRowId);
        }

        // handle multi-selection
        selectedRowIds.forEach((rowId) => selectRow(rows, rowId, DATA_ATTRIBUTE));

        // set the header checkbox state according to the selected rows
        setHeaderCheckboxState(rows, selectedRowIds);
    };

    const isTargetCheckbox = (target) => {
        const classListValue = target.classList.value;
        return classListValue === 'checkbox-text' || classListValue === 'checkbox';
    };

    const handleActiveRowChange = (event) => {
        event.preventDefault();
        event.stopPropagation();

        // handle selection if checkbox was clicked, else toggle row as active
        if (isTargetCheckbox(event.target)) {
            handleSelection(event);
        } else {
            const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);
            setLastActiveRowId(activeRowId);
            setActiveRowId(activeRowId === rowId ? '' : rowId);
        }
    };

    const handleSelection = (event) => {
        const selectedRowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);
        if (selectedRowIds.includes(selectedRowId)) {
            setSelectedRowIds(selectedRowIds.filter((rowId) => rowId !== selectedRowId));
            setSelectedData(selectedData.filter((d) => d.id !== selectedRowId));
            deselectRows([selectedRowId]);
        } else {
            setSelectedRowIds([...selectedRowIds, selectedRowId]);
            const dataToSelect = data.filter((d) => d.id === selectedRowId);
            setSelectedData([...selectedData, ...dataToSelect]);
        }
    };

    const handleToggleAll = (shouldSelect) => {
        const allRowIds = data.map((d) => `${d.id}`);
        // Deselect all rows when there is at least one row selected, else select all rows
        if (shouldSelect) {
            setSelectedRowIds(allRowIds);
            setSelectedData(data);
        } else {
            setSelectedRowIds([]);
            setSelectedData([]);
            deselectRows(allRowIds);
        }
    };

    const deselectRows = (rowIds) => {
        const rows = [...tableRef.current.rows];
        rowIds.forEach((rowId) => deselectRow(rows, rowId, DATA_ATTRIBUTE));
    };

    const getSortDir = (sortBy, previousSortBy) => {
        if (sortBy === previousSortBy) {
            return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    const handleSortChange = (event) => {
        const sortByToUpdate = event.currentTarget.getAttribute('data-sortby');
        setSortBy(sortByToUpdate);
        setSortDir(getSortDir(sortByToUpdate, sortBy));
    };

    const handleSearchValueChange = (search) => {
        setQuery(search);
        setActive(1);
    };

    const setPage = (page) => {
        setOffset(page * limit);
        setSize(Math.ceil(dataTotal / limit));
    };

    const setLimitAndPage = (value) => {
        setLimit(value);
        setActive(1);
    };

    const dataLimit = [
        {
            value: '10',
            onSelect: () => setLimitAndPage(10),
        },
        {
            value: '20',
            onSelect: () => setLimitAndPage(20),
        },
        {
            value: '30',
            onSelect: () => setLimitAndPage(30),
        },
    ];

    // May be extracted as a dedicated component but for demo purpose it's shown here
    const renderTableHead = (column, label) => {
        const tableHeadClassNames = classNames('user-select-none', 'sort-column');
        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
                title={`${t(label)}`}
            >
                <span>
                    {sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows />}
                    <span>{`${t(label)}`}</span>
                </span>
            </th>
        );
    };

    const renderTableCol = (column, columnDetails) => {
        const style = columnDetails?.width
            ? {
                  minWidth: columnDetails.width,
                  width: columnDetails.width,
              }
            : {};

        return <col key={column} style={style} />;
    };

    const Pagination = ({ active, size, step, onClickHandler }) => {
        const showingNumbers = Math.min(size, step * 2 + 1);
        const startNumber = Math.max(2, active - step);
        const needStartDots = active > step + 1;
        const needEndDots = size > active + step + 1;

        return (
          <div className='text-center margin-top-25' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className='text-left'>
                  <ButtonDropdown
                    className='page-size'
                    id='table-length'
                    title={limit}
                    items={dataLimit}
                    dropup
                    splitButton
                  />
              </div>
              <nav className='pagination-wrapper' aria-label='Page navigation' style={{ flexGrow: 1, textAlign: 'center' }}>
                  <ul className='pagination'>
                      {/* Prev Button */}
                      <li className={active > 1 ? '' : 'disabled'}>
                          <span onClick={() => active > 1 && onClickHandler(active - 1)}
                                className='rioglyph rioglyph-chevron-left'
                                aria-hidden='true'
                          />
                      </li>

                      {/* First Page */}
                      <li className={`page-item ${active === 1 ? 'active' : ''}`}>
                          <a onClick={() => onClickHandler(1)}>
                              <span>1</span>
                          </a>
                      </li>

                      {/* Start Dots */}
                      {needStartDots && (
                        <li>
                            <span className='rioglyph rioglyph-option-horizontal' />
                        </li>
                      )}

                      {/* Page Numbers */}
                      {Array.from({ length: showingNumbers }).map((_, index) => {
                          const pageNumber = startNumber + index;
                          if (pageNumber >= size) return null;
                          return (
                            <li key={pageNumber} className={`page-item ${active === pageNumber ? 'active' : ''}`}>
                                <a onClick={() => onClickHandler(pageNumber)}>
                                    <span>{pageNumber}</span>
                                </a>
                            </li>
                          );
                      })}

                      {/* End Dots */}
                      {needEndDots && (
                        <li>
                            <span className='rioglyph rioglyph-option-horizontal' />
                        </li>
                      )}

                      {/* Last Page */}
                      {size > 1 && (
                        <li className={`page-item ${active === size ? 'active' : ''}`}>
                            <a onClick={() => onClickHandler(size)}>
                                <span>{size}</span>
                            </a>
                        </li>
                      )}

                      {/* Next Button */}
                      <li className={active < size ? '' : 'disabled'}>
                          <span
                            onClick={() => active < size && onClickHandler(active + 1)}
                            className='rioglyph rioglyph-chevron-right'
                            aria-hidden='true'
                          />
                      </li>
                  </ul>
              </nav>
          </div>
        );
    };

    // filter for hidden columns
    const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));
    // filter data to omit hidden columns
    const withoutHidden = omit(hiddenColumns);
    // data without hidden columns
    const rows = data?.map((d) => ({ ...withoutHidden(d) }));
    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-head-filled',
    );

    const batchButton = (
        <ButtonDropdown
            title={<span className='rioglyph rioglyph rioglyph-checkboxes' />}
            className={'btn-s'}
            bsStyle={'link'}
            iconOnly
            items={[
                {
                    value: (
                        <div>
                            <span>{`${t("fotaone.general.table.selectAll")}`}</span>
                        </div>
                    ),
                    onSelect: () => handleToggleAll(true),
                },
                {
                    value: (
                        <div>
                            <span>{`${t("fotaone.general.table.deselectAll")}`}</span>
                        </div>
                    ),
                    onSelect: () => handleToggleAll(false),
                },
            ]}
        />
    );

    return (
        <div id='FileCheckTable'>
            <TableToolbar>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={query}
                                onChange={handleSearchValueChange}
                                placeholder={`${t("fotaone.general.table.tableSearch")}`}
                            />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            {loading ? (
                <Loader center />
            ) : rows.length ? (
                <div className='table-wrapper'>
                    <table ref={tableRef} className={tableClassNames}>
                        {/* Colgroups are required to work with column widths */}
                        <colgroup>
                            <col className='table-checkbox' />
                            {columns.map((column) => renderTableCol(column, columnDetails[column]))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='table-checkbox'>{batchButton}</th>
                                {columns.map((column) =>
                                    renderTableHead(
                                        column,
                                        mapFileCheckTableData.columnLabels[column],
                                        columnDetails[column],
                                    ),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    row={row}
                                    columns={columns}
                                    colLabels={mapFileCheckTableData.columnLabels}
                                    onClick={handleActiveRowChange}
                                    onSelectCheckbox={handleSelection}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotFoundState headline={`${t("fotaone.mu.noFilesFound")}`} message='' />
            )}
            <Pagination active={active} size={size} step={step} onClickHandler={activeHandler} />
        </div>
    );
};

export const getRowByDataAttribute = (rows = [], value = '', attribute = 'data-key') =>
    rows.find((row) => {
        const dataAttribute = row.attributes[attribute];
        if (dataAttribute) {
            return dataAttribute.value === value;
        }
        return false;
    });

export const highlightRow = (rows, driverId) => {
    const row = getRowByDataAttribute(rows, driverId);
    if (row) {
        row.classList.add(ACTIVE_CLASS);
    }
};

export const removeHighlightFromRow = (rows, driverId) => {
    const row = getRowByDataAttribute(rows, driverId);
    if (row) {
        row.classList.remove(ACTIVE_CLASS);
    }
};

const getCheckboxCell = (row) =>
    [...row.children].find((child) => child.className && child.className === 'table-checkbox');

export const selectRow = (rows, rowId, dataAttribute) => {
    const row = getRowByDataAttribute(rows, rowId, dataAttribute);
    if (row) {
        // Accessing the input node directly is slightly faster than using
        //    const [inputNode] = row.getElementsByTagName('input');
        //    inputNode.checked = true;
        // Means, when having many nodes like a thousand or more, acessing it directly
        // results in better performance,
        // row.firstChild.firstChild.td[].checked = true;
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = true;
        }
    }
};

export const deselectRow = (rows, rowId, dataAttribute) => {
    const row = getRowByDataAttribute(rows, rowId, dataAttribute);
    if (row) {
        // Accessing the input node directly is slightly faster than using
        //    const [inputNode] = row.getElementsByTagName('input');
        //    inputNode.checked = false;
        // Means, when having many nodes like a thousand or more, acessing it directly
        // results in better performance,
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = false;
        }
    }
};

export const setHeaderCheckboxState = (rows, selectedRowIds) => {
    const [headerRow] = rows;
    if (headerRow) {
        const checkboxCell = getCheckboxCell(headerRow);
        if (checkboxCell) {
            const checkbox = checkboxCell.firstChild.firstChild;

            const amountRows = rows.length - 1; // without the header row
            const amountSelectedRows = selectedRowIds.length;

            if (amountRows === amountSelectedRows) {
                checkbox.indeterminate = false;
                checkbox.checked = true;
            } else if (!amountSelectedRows) {
                checkbox.indeterminate = false;
                checkbox.checked = false;
            } else {
                checkbox.indeterminate = true;
            }
        }
    }
};

export default FileCheckTable;
