import { Observer, observer } from 'mobx-react-lite';
import Table from '@common/Table/Table.jsx';
import {
    installedMapsTableData,
} from '@components/mapupdate/vehicles/overview/Vehicle.constants.jsx';
import Pagination from '@common/Pagination/Pagination.jsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import { debounce } from 'lodash';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import SimpleTooltip from '@rio-cloud/rio-uikit/lib/es/SimpleTooltip';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { setQueryParamToUrl } from '@components/mapupdate/mapupdateHelper/utils.jsx';
import { SupportedFileTypes } from '@stores/exports.js';
import { cloneObject } from '@helpers/object.js';
import { useTranslation } from 'react-i18next';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
  query: '',
  sortBy: 'vin',
  sortDir: SortDirection.DESCENDING,
});
export default observer(() => {
  const { assetStore, exportsStore, serviceStore } = useStores();
  const [showInfoContent, setShowInfoContent] = useState(false);
  const { t, i18n } = useTranslation();
  const query = filterStore?.query;
  const activePage = paginationStore?.activePage;
  const limit = paginationStore?.limit;
  const offset = paginationStore?.offset;
  const sortBy = filterStore?.sortBy;
  const sortDir = filterStore?.sortDir;
  const searchVehicles = useCallback(
    debounce(async (params) => {
      await assetStore.getLastMapsVersions();
      await assetStore.getVehiclesInstalledMapsGraphql(params);
      paginationStore.setItemsLength(assetStore.installedMapsTotal)
    }, 200),[],
  );

  useEffect(() => {
    searchVehicles({
      query,
      sortBy,
      sortDir,
      limit,
      offset,
    });
  }, [
    query,
    sortBy,
    sortDir,
    limit,
    activePage,
    offset,
  ]);

  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  const handleExportButton = async () => {
    const params = {
        type: SupportedFileTypes.CSV,
        feature: serviceStore.currentService,
        page: 'installedMapPage',
        headers: cloneObject(installedMapsTableData.columns),
        query,
        sortBy,
        sortDir,
    };
    await exportsStore.exportAsFile(params);
    assetStore.triggerReload();
  };

  const renderTableInfo= () => {
    return (
      <>
        <div className='row'>
          <div className='col-md-6'>
            <h5 className=''>{`${t("fotaone.mu.installedMapsPage.latestMapVersions")}`}</h5>
            <div>
              {assetStore?.lastMapsVersions.map((version, index) => (
                <span key={version}>
                  <span
                    className={`text-size-h3 margin-right-3 ${index === 0 ? 'text-color-success' 
                      : index < 3 ? 'text-color-warning' : 'text-color-danger'}`}
                  >
                    {version}
                  </span>
                  {index < assetStore.lastMapsVersions.length - 1 && (
                    <span className='text-size-h3 margin-right-3 text-bold'>|</span>
                  )}
                </span>
              ))}
            </div>
          </div>
          <div className='col-md-6'>
            <h5>{t("fotaone.mu.installedMapsPage.symbols")}</h5>
            <div className={'margin-bottom-5'}>
              <span className='rioglyph rioglyph-beacon text-size-16'/>
              <span>{t("fotaone.mu.installedMapsPage.currentlyBookedIcon")}</span>
            </div>
            <div className={'display-flex margin-bottom-5'}>
              <div className={'bg-success rounded height-15 width-15 margin-right-5 position-relative'} style={{top: 3}}/>
              <span>{t("fotaone.mu.installedMapsPage.greenColorExplanation")}</span>
            </div>
            <div className={'display-flex margin-bottom-5'}>
              <div className={'bg-warning rounded height-15 width-15 margin-right-5 position-relative'} style={{top: 3}}/>
              <span>{t("fotaone.mu.installedMapsPage.yellowColorExplanation")}</span>
            </div>
            <div className={'display-flex'}>
              <div className={'bg-danger rounded height-15 width-15 margin-right-5 position-relative'} style={{top: 3}}/>
              <span>{t("fotaone.mu.installedMapsPage.redColorExplanation")}</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='app-file-table'>
      <Collapse
          open={showInfoContent}
          unmountOnExit
      >
          <div className='panel-wrapper margin-bottom-10'>
              <div className='panel panel-info'>
                  <div className='panel-body'>{renderTableInfo()}</div>
              </div>
          </div>
      </Collapse>
      <Table 
        toolbarElement={
          <>
              <div className='table-toolbar-column'>
                <SimpleTooltip
                  content={t("fotaone.mu.installedMapsPage.captionButtonTooltip")}
                >
                  <Button
                    className='btn btn-default btn-icon-only'
                    onClick={() => setShowInfoContent(!showInfoContent)}
                    active={showInfoContent}
                  >
                    <span
                      className={'rioglyph-detail-view-info'}
                      style={{ fontSize: '2.3rem' }}
                    />
                  </Button>
                </SimpleTooltip>
              </div>
              <div className='table-toolbar-column'>
                  <Observer>
                      {() => (
                          <SimpleTooltip
                              content={t('fotaone.general.tooltip.exportCsv')}
                          >
                              <Button
                                  className='btn btn-default btn-icon-only'
                                  onClick={handleExportButton}
                                  id={'export-map-update'}
                                  disabled={exportsStore?.stateMachine?.isLoading}
                              >
                                  <span
                                      className={`rioglyph ${exportsStore?.stateMachine?.isLoading ? 'rioglyph-spinner spinning  ' : 'rioglyph-csv'}`}
                                      style={{ fontSize: '2.3rem' }}
                                  />
                              </Button>
                          </SimpleTooltip>
                      )}
                  </Observer>
              </div>
          </>
        }
        showSearch={true}
        data={assetStore.installedMapsList || []}
        rowKey='vin'
        handleSearchValueChange={handleSearchValueChange}
        handleSortChange={handleSortChange}
        searchValue={query}
        columnDetails={installedMapsTableData.columnDetails}
        defaultColumnOrder={installedMapsTableData.defaultColumnOrder}
        columns={installedMapsTableData.columns}
        columnOrder={installedMapsTableData.columnsOrder}
        hiddenColumns={installedMapsTableData.hiddenColumns}
        columnLabels={installedMapsTableData.columnLabels}
        disabledColumns={installedMapsTableData.disabledColumns}
        actionButtons='buttons'
        loading={assetStore.assetsState.isLoading}
      />
      <Pagination paginationStore={paginationStore} />
    </div>
  );
});
