import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty, debounce } from 'lodash';
import prettyBytes from 'pretty-bytes';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants';
import './FileDetails.css';
import { getRegion } from '../mapupdate/mapupdateHelper/region.jsx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { useTranslation } from 'react-i18next';

export const FileDetails = observer((props) => {
    const { id } = props;
    const { paginationStore, filterStore } = props;
    const { serviceStore, filesStore, authStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fileId = get(filesStore.currentFile, ['maps', '0', 'file', 'file_id']);

    const approvalState = get(filesStore.currentFile, ['maps', '0', 'approval_state']);
    const releasedByEmail = get(filesStore.currentFile, ['map_lifecycle', '0', 'released_by_email']);

    const limit = paginationStore?.limit;
    const offset = paginationStore?.offset;
    const query = filterStore?.query;
    const sortDir = filterStore?.sortDir;
    const sortBy = filterStore?.sortBy;

    const searchFiles = useCallback(
        debounce((params) => filesStore.getFilesGraphql(params), 200),
        [],
    );

    useEffect(() => {
        filesStore.getFileDetails(id);
    }, [id, filesStore]);

    const onClose = () => {
        navigate(`${serviceStore.currentService}/files`);
    };

    const downloadFile = async (fileId) => {
        try {
            const { downloadUrl } = await filesStore.getFile(fileId);
            const newWindow = window.open('', '_blank');
            if (newWindow) {
                newWindow.location.href = downloadUrl;
            } else {
                Notification.error("Pop-up blocked by browser settings. Please allow pop-ups for this site to download the file.");
            }
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${t("fotaone.notification.error.files.failedDownload")}`);
            }
        }
    };
    const hasReleasePermission = () => {
        return authStore.roles.includes('PO');
    };

    const updateState = async (id, newState) => {
        await filesStore.updateApprovalState(id, newState);
        filesStore.getFileDetails(id);
        searchFiles({
            limit,
            offset,
            query,
            sortBy,
            sortDir,
        });
    };
    const renderDetails = () => {
        if (!filesStore.currentFile || isEmpty(filesStore.currentFile)) {
            return <NotFoundState headline={`${t("fotaone.general.table.noData")}`} />;
        }
        return (
            <>
                <button
                    type='button'
                    className='btn btn-primary margin-top-10 margin-bottom-10 margin-right-10'
                    onClick={() => downloadFile(fileId)}
                >
                    <span className='rioglyph rioglyph-download' />
                    {`${t("fotaone.mu.fileData.downloadFile")}`}
                </button>
                {hasReleasePermission() &&
                    (approvalState === 'RELEASED' ? (
                        <button
                            type='button'
                            id='unrelease'
                            className='btn btn-danger margin-top-10 margin-bottom-10 margin-right-10'
                            onClick={() => updateState(id, 'UNRELEASED')}
                        >
                            <span className='rioglyph rioglyph-eye-close' />
                            {`${t("fotaone.mu.fileData.state.unrelease")}`}
                        </button>
                    ) : (
                        <button
                            type='button'
                            id='release'
                            className='btn btn-success margin-top-10 margin-bottom-10 margin-right-10'
                            onClick={() => updateState(id, 'RELEASED')}
                        >
                            <span className='rioglyph rioglyph-eye-option' />
                            {`${t("fotaone.mu.fileData.state.release")}`}
                        </button>
                    ))}
                <h5>{`${t("fotaone.general.general")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.displayName")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'display_name'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileName")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'file', 'filename'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileSize")}`}</label>
                            <span className='text-primary'>
                                {prettyBytes(get(filesStore.currentFile, ['maps', '0', 'file', 'size_bytes'], 0))}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.version")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'version_number'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.preVersion")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'previous_version_number'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label className='display-block'>{`${t("fotaone.mu.fileData.fileChecksumMD5")}`}</label>
                        <span className='text-primary'>
                            <pre>
                                {
                                    get(filesStore.currentFile, ['maps', '0', 'file', 'checksum_md5'])?.trim()
                                      ? get(filesStore.currentFile, ['maps', '0', 'file', 'checksum_md5'])
                                      : t("fotaone.general.notApplicable")
                                }
                            </pre>
                        </span>
                    </div>
                    <div className='col-md-12'>
                        <label className='display-block'>{`${t("fotaone.mu.fileData.fileChecksumSHA256")}`}</label>
                        <span className='text-primary'>
                            <pre>{get(filesStore.currentFile, ['maps', '0', 'file', 'checksum_sha256'])}</pre>
                        </span>
                    </div>
                </div>
                <h5>{`${t("fotaone.mu.fileData.fileParams.interactions")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.uploadedBy")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['map_lifecycle', '0', 'uploaded_by_email'])}
                            </span>
                        </div>
                        {releasedByEmail === null ? (
                            <div />
                        ) : approvalState === 'RELEASED' ? (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.releasedBy")}`}</label>
                                <span className='text-primary'>{releasedByEmail}</span>
                            </div>
                        ) : (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.unreleasedBy")}`}</label>
                                <span className='text-primary'>{releasedByEmail}</span>
                            </div>
                        )}
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.uploadedAt")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['map_lifecycle', '0', 'uploaded_at'])}
                            </span>
                        </div>
                        {releasedByEmail === null ? (
                            <div />
                        ) : approvalState === 'RELEASED' ? (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.releasedAt")}`}</label>
                                <span className='text-primary'>
                                    {get(filesStore.currentFile, ['map_lifecycle', '0', 'released_at'])}
                                </span>
                            </div>
                        ) : (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.unreleasedAt")}`}</label>
                                <span className='text-primary'>
                                    {get(filesStore.currentFile, ['map_lifecycle', '0', 'released_at'])}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <h5>{`${t("fotaone.mu.fileData.fileParams.parameters")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.regionData.region.one")}`}</label>
                            <span className='text-primary'>
                                {`${t(getRegion(get(filesStore.currentFile, ['maps', '0', 'map_region_enum'])))}`}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.state.fileState")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'approval_state'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.updateMandatory")}?`}</label>
                            <span className='text-primary'>
                                {`${t(get(filesStore.currentFile, ['maps', '0', 'mandatory_update']) ? "fotaone.general.yes" : "fotaone.general.no")}`}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.updateRecommended")}?`}</label>
                            <span className='text-primary'>
                                  {`${t(get(filesStore.currentFile, ['maps', '0', 'update_recommended']) ? "fotaone.general.yes" : "fotaone.general.no")}`}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <DetailsSideBar
            title={`${t("fotaone.mu.fileData.fileDetails")}`}
            onClose={onClose}
            width={600}
            blurEffect={
                filesStore?.fileDetailsState?.isLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide
            }
            fly
        >
            {renderDetails()}
        </DetailsSideBar>
    );
});

export default FileDetails;
