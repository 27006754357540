import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import DetailsGeneral from '@components/RemoteMessage/Common/DetailsGeneral.jsx';
import DetailsTarget from '@components/RemoteMessage/Common/DetailsTarget.jsx';
import DetailsMessage from '@components/RemoteMessage/Common/DetailsMessage.jsx';
import DetailsEvents from '@components/RemoteMessage/Common/DetailsEvents.jsx';
import { ServiceRoles } from '@helpers/constants.jsx';

const ToApproveDetailSidebar = (props) => {
  const { serviceStore, authStore, remoteMessageStore } = useStores();
  const { messageId, setShowDecisionConfirmationDialog, setConfirmationType } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const close = async () => {
    navigate(`${serviceStore.currentService}/toApprove`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await remoteMessageStore.getMessageDetails(messageId);
    };
    fetchData();
  }, [messageId]);

  const ActionButton = ({ iconClass, buttonClass, label, onClick }) => (
    <Button
      type='button'
      className={`btn ${buttonClass} margin-right-3`}
      onClick={onClick}
    >
      <span className={`rioglyph ${iconClass}`}/>
      <span>{label}</span>
    </Button>
  );

  const renderActions = (row) => (
    authStore.roles.includes(ServiceRoles.RemoteMessageCountryManagerGlobal) ?(
      <div className='text-right'>
        <div className='row margin-right-3'>
            <ActionButton
              iconClass='rioglyph-ok'
              buttonClass='btn-outline btn-success'
              label={`${t("fotaone.rm.messageData.messageAction.approveMessage")}`}
              onClick={() => {
                setConfirmationType('approval');
                remoteMessageStore.setHandleMessageDecision(remoteMessageStore.messageDetails);
                setShowDecisionConfirmationDialog(true);
              }}
            />
            <ActionButton
              iconClass='rioglyph-remove'
              buttonClass='btn-outline btn-danger'
              label={`${t("fotaone.rm.messageData.messageAction.rejectMessage")}`}
              onClick={(e) => {
                setConfirmationType('rejection');
                remoteMessageStore.setHandleMessageDecision(remoteMessageStore.messageDetails);
                setShowDecisionConfirmationDialog(true);
              }}
            />
        </div>
      </div>
    ):(<></>)
  );

  return (
    <DetailsSideBar
      title={`${t("fotaone.rm.messageData.messageDetails")}`}
      onClose={close}
      width={600}
      blurEffect={remoteMessageStore.messageDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
      fly
    >
      <div className='flex-1-1'>
        <div className='display-flex justify-content-end align-items-start margin-bottom-10'>
          {renderActions()}
        </div>
      </div>
      <div className='details'>
        <DetailsGeneral />
        <DetailsTarget />
        <DetailsMessage />
        <DetailsEvents />
      </div>
    </DetailsSideBar>
  );
};

export default observer(ToApproveDetailSidebar);