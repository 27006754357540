import React, { useState } from 'react';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import FileTable from '../../../../Files/FileTable.jsx';
import prettyBytes from 'pretty-bytes';
import { useStores } from '@stores/context.jsx';
import CampaignTargetSelection from './CampaignTargetSelection.jsx';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import { stepperCreateCampaignsInitState, stepperCreateCampaignsLabel } from '../Campaign.constants.jsx';
import { filenameFromAllNames, fileStatusRenderer } from '../../../mapupdateHelper/utils.jsx';
import { useTranslation } from 'react-i18next';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();
/**
 * Dialog with multi step component for campaign creation.
 * Notes:
 * - Some existing components are being used as Steps. In those cases the props.isStep is set to true. The
 * props.data and props.onChange should be properly handled. Alternative: use global state (mobx) or react context.
 */

export default () => {
    // *** INIT ****
    const { campaignStore } = useStores();
    const [showCreateCampaignDialog, setShowCreateCampaignDialog] = useState(false);
    const [stepperData, setStepperData] = useState(stepperCreateCampaignsInitState);
    const [selectedStep, setSelectedStep] = useState(0);
    const { t } = useTranslation();

    // steps
    const steps = [FileTable, CampaignTargetSelection, CampaignPreviewStep];
    stepperCreateCampaignsLabel.forEach((element) => {
        element.label = `${t(element.label)}`;
    });

    // *** HANLDERS ****
    const onUpdateData = (updatedData) => {
        const data = {
            ...stepperData,
            ...updatedData,
        };
        setStepperData(data);
    };

    const onNextStep = () => {
        let update_step = selectedStep + 1;
        if (update_step > steps.length) {
            update_step = steps.length;
        }
        setSelectedStep(update_step);
    };

    const onPreviousStep = () => {
        let update_step = selectedStep - 1;
        if (update_step < 0) {
            update_step = 0;
        }
        setSelectedStep(update_step);
    };

    const onSelectedChanged = (s) => {
        setSelectedStep(s);
    };

    const onShow = () => {
        setShowCreateCampaignDialog(true);
    };

    const onHide = () => {
        setStepperData(stepperCreateCampaignsInitState);
        setSelectedStep(0);
        setShowCreateCampaignDialog(false);
    };

    const onChangeCustomName = (newCampaignSuffix) => {
        setCustomCampaignSuffix(newCampaignSuffix);
        setStepperData({ customName: newCampaignSuffix });
    };

    const onFinish = () => {
        campaignStore.createCampaign(stepperData);
        onHide();
    };

    // *** RENDERS ****
    const render = () => {
        const prev_button = (
            <button type='button' className='btn btn-primary' onClick={onPreviousStep}>
                {`${t("fotaone.general.previous")}`}
            </button>
        );

        let next_button_disabled = false;
        // validation for step 0
        if (selectedStep === 0 && !stepperData.selectedMap) {
            next_button_disabled = true;
        }

        const next_button = (
            <button type='button' className='btn btn-primary' onClick={onNextStep} disabled={next_button_disabled}>
                {`${t("fotaone.general.next")}`}
            </button>
        );

        const finish_button = (
            <button type='button' className='btn btn-primary' onClick={onFinish}>
                {`${t("fotaone.mu.campaignData.createCampaign")}`}
            </button>
        );

        const footer = (
            <div className='pull-right btn-toolbar'>
                {selectedStep > 0 && prev_button}
                {selectedStep < steps.length - 1 && next_button}
                {selectedStep === steps.length - 1 && finish_button}
            </div>
        );

        const CurrentStep = steps[selectedStep];

        const body = (
            <div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <SteppedProgressBar
                            selectedStepNumber={selectedStep}
                            onSelectedChanged={onSelectedChanged}
                            labels={stepperCreateCampaignsLabel}
                        />
                    </div>
                </div>
                <div className='row equal-height'>
                    <div className='col-xs-12 col-md-12 margin-bottom-15'>
                        <div className='panel panel-default panel-body height-100pct'>
                            <CurrentStep
                                className={'table-wrapper'}
                                paginationStore={paginationStore}
                                filterStore={filterStore}
                                isStep={true}
                                data={stepperData}
                                onChange={onUpdateData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div>
                <div className='text-right table-toolbar-column'>
                    <button type='button' className='btn btn-primary' onClick={onShow}>
                        {`${t("fotaone.mu.campaignData.createCampaign")}`}
                    </button>
                </div>
                <Dialog
                    show={showCreateCampaignDialog}
                    title= {`${t("fotaone.mu.campaignData.createCampaign")}`}
                    body={body}
                    footer={footer}
                    onHide={onHide}
                    className='campaigns-creation-form'
                    showCloseButton={true}
                />
            </div>
        );
    };
    return render();
};

/**
 * Step for campaign confirmation.
 */
const CampaignPreviewStep = (props) => {
    const [customCampaignSuffix, setCustomCampaignSuffix] = useState('');
    const { t } = useTranslation();

    const showCampaignName = () => {
        const campaignName = `${props.data.selectedMap.map_region_enum} -  
            ${props.data.selectedMap.previous_version_number} to 
            ${props.data.selectedMap.version_number}`;

        return props.data.customName === '' ? campaignName : `${campaignName} - ${props.data.customName}`;
    };

    const onChangeCustomName = (campaignSuffix) => {
        if (props.isStep) {
            props.onChange({
                customName: campaignSuffix,
            });
        }
    };

    return (
        <div className='campaign-summary'>
            <h4>{`${t("fotaone.mu.campaignData.campaignPreview")}`}</h4>
            <p>{`${t("fotaone.mu.campaignData.campaignPreviewInfo")}`}</p>
            <div className='margin-bottom-10'>
                <label className='display-block'>{`${t("fotaone.mu.fileData.displayName")}:`}</label>
                <span className='text-primary'>
                    {props.data.selectedMap.display_name}
                    {fileStatusRenderer('', props.data.selectedMap, false)}
                </span>
            </div>
            <div className='margin-bottom-10'>
                <label className='display-block'>{`${t("fotaone.mu.fileData.fileName")}:`}</label>
                <span className='text-primary'>{filenameFromAllNames(props.data.selectedMap.all_names)}</span>
            </div>
            <div className='margin-bottom-10'>
                <label className='display-block'>{`${t("fotaone.mu.fileData.fileSize")}:`}</label>
                <span className='text-primary'>{prettyBytes(props.data.selectedMap.map_size)}</span>
            </div>
            <div className='margin-bottom-10'>
                <label className='display-block'>{`${t("fotaone.mu.regionData.region.one")}:`}</label>
                <span className='text-primary'>{props.data.selectedMap.map_name}</span>
            </div>
            {(props.data.selectedVehicleIds.length > 0 || props.data.selectedGroupIds.length > 0) && (
                <div className='margin-bottom-10'>
                    <label className='display-block'>{`${t("fotaone.mu.campaignData.campaignTargetInfo")}`}</label>
                    <span className='text-primary'>
                        {props.data.selectedVehicleIds.length > 0
                            ? props.data.selectedVehicleIds.length === 1  ? `1 ${t("fotaone.general.vehicleData.vehicle.one")}` : `${props.data.selectedVehicleIds.length} ${t("fotaone.general.vehicleData.vehicle.many")}`
                            : props.data.selectedGroupIds.length === 1 ? `1 ${t("fotaone.mu.groupData.group.one")}` : `${props.data.selectedGroupIds.length} ${t("fotaone.mu.groupData.group.many")}`
                        }
                    </span>
                </div>
            )}
            <div className='margin-bottom-10'>
                <label className='display-block'>{`${t("fotaone.mu.campaignData.campaignName")}`}</label>
                <span className='text-primary'>{showCampaignName()}</span>
            </div>
            <label htmlFor='inputCustomName'>{`${t("fotaone.mu.campaignData.campaignSuffix")}`}</label>
            <input
                className='form-control'
                id='inputCustomName'
                placeholder={`${t("fotaone.mu.campaignData.campaignSuffixInfo")}`}
                type='text'
                value={props.data.customName}
                onChange={(event) => onChangeCustomName(event.target.value)}
            />
        </div>
    );
};
