import React from 'react';

import {
    distanceTimeRenderer,
    eventRenderer,
    filenameInformation,
    filenameRender,
    fileStatusRenderer,
    rolloutTimeoutBadge,
    subjectRenderer,
    testBadgeRenderer,
    timestampInformation,
    verifyChecksumInstruction,
    wordShortenerRender,
} from './utils.jsx';
import { getRegion } from './region.jsx';
import { formatISO9075 } from 'date-fns';

export const DEFAULT_LIMIT = 5;

export const regionSelectData = [
    { E1: 'fotaone.mu.regionData.E1' },
    { E2: 'fotaone.mu.regionData.E2' },
    { E3: 'fotaone.mu.regionData.E3' },
    { E4: 'fotaone.mu.regionData.E4' },
    { E5: 'fotaone.mu.regionData.E5' },
    { E6: 'fotaone.mu.regionData.E6' },
    { E7: 'fotaone.mu.regionData.E7' },
    { E8: 'fotaone.mu.regionData.E8' },
    { E9: 'fotaone.mu.regionData.E9' },
    { EA: 'fotaone.mu.regionData.EA' },
    { EB: 'fotaone.mu.regionData.EB' },
    { EC: 'fotaone.mu.regionData.EC' },
    { ED: 'fotaone.mu.regionData.ED' },
    { EE: 'fotaone.mu.regionData.EE' },
    { EF: 'fotaone.mu.regionData.EF' },
    { EG: 'fotaone.mu.regionData.EG' },
    { EH: 'fotaone.mu.regionData.EH' },
];
export const statusColorCode = {
    UNKNOWN: 'status-gray',
    QUEUED: 'status-gray',
    ACTIVATION_PENDING: 'status-orange',
    MAP_STATE_UPDATE_INITIATED: 'status-blue',
    APP_STATE_UPDATE_INITIATED: 'status-blue',
    MAP_UPDATES_OFFERED: 'status-blue',
    DOWNLOAD_INITIATED: 'status-blue',
    DOWNLOAD_IN_PROGRESS: 'status-blue',
    MDM_DOWNLOAD_COMPLETED: 'status-blue',
    DOWNLOADS_COMPLETED: 'status-blue',
    MAP_UPDATES_PATCHED: 'status-blue',
    MAP_UPDATES_PATCHING: 'status-blue',
    MAP_UPDATES_REJECTED: 'status-red',
    COMPLETED: 'status-green',
    CANCELED: 'status-red',
    FAILED: 'status-red',
    FAILED_REPEATEDLY: 'status-red',
    SUSPENDED: 'status-orange',
    CREATED: 'status-blue',
    STOPPED: 'status-red',
    STARTED: 'status-green',
    'IN PROGRESS': 'status-blue',
};
export const statusPercentage = {
    UNKNOWN: '1',
    QUEUED: '10',
    ACTIVATION_PENDING: '10',
    MAP_STATE_UPDATE_INITIATED: '20',
    APP_STATE_UPDATE_INITIATED: '20',
    MAP_UPDATES_OFFERED: '30',
    DOWNLOAD_INITIATED: '40',
    DOWNLOAD_IN_PROGRESS: '40',
    MDM_DOWNLOAD_COMPLETED: '75',
    DOWNLOADS_COMPLETED: '75',
    MAP_UPDATES_PATCHING: '80',
    MAP_UPDATES_PATCHED: '90',
    MAP_UPDATES_REJECTED: '100',
    COMPLETED: '100',
    CANCELED: '100',
    FAILED: '100',
    FAILED_REPEATEDLY: '100',
    SUSPENDED: '0',
};
export const rolloutStatusMap = {
    InitialRolloutState: 'QUEUED',
    ActivationPendingState: 'ACTIVATION_PENDING',
    MapUpdateInitiatedState: 'MAP_STATE_UPDATE_INITIATED',
    MapUpdatesOfferedState: 'MAP_UPDATES_OFFERED',
    DownloadInitiatedState: 'DOWNLOAD_INITIATED',
    DownloadInProgressState: 'DOWNLOAD_IN_PROGRESS',
    VcmDownloadCompletedState: 'MDM_DOWNLOAD_COMPLETED',
    DownloadsCompleteState: 'DOWNLOADS_COMPLETED',
    MapUpdatesPatchingState: 'MAP_UPDATES_PATCHING',
    MapUpdatesPatchedState: 'MAP_UPDATES_PATCHED',
    MapUpdatesRejectedState: 'MAP_UPDATES_REJECTED',
    RolloutDoneState: 'COMPLETED',
    RolloutCanceledState: 'CANCELED',
    RolloutFailedState: 'FAILED',
    RolloutFailedRepeatedlyState: 'FAILED_REPEATEDLY',
    SuspendedRolloutState: 'SUSPENDED',
    AppUpdateInitiatedState: 'APP_STATE_UPDATE_INITIATED',
};
export const RESTART_ALLOWED_STATES = [
    rolloutStatusMap.ActivationPendingState,
    rolloutStatusMap.RolloutFailedRepeatedlyState,
];
export const FINAL_ROLLOUT_STATES = ['COMPLETED', 'FAILED', 'CANCELED'];

//Ref.: Blocked Head Unit Versions: 'https://gitlab.cicd.man/initiative/digitalmanufaktur/fota/fota-mapupdate/-/blob/master/src/main/resources/application.yml?ref_type=heads#L181'
export const blockedMmtVersions = ['1.0','MMT-ADV_ENGG_DROP-M21142B','MMT-ADV-ENGG-DROP-R20491A','MMT-ADV-High-XP10.3-R20324A','MMT-ADV-High-XP11.0-R20384A','MMT-ADV-High-XP11.1-R20403A','MMT-ADV-High-XP11.2-R20432A','MMT-ADV-High-XP11.3-R20463A','MMT-ADV-Mid-X10.3-R20324A','MMT-ADV-Mid-X10.6-R20364A','MMT-ADV-Mid-X11.0-R20357A','MMT-ADV-Mid-X11.0-R20384A','MMT-ADV-Mid-X11.1-R20403A','MMT-ADV-Mid-X11.2-R20432A','MMT-ADV-Mid-X11.3-R20452A','MMT-ADV-Mid-X11.3-R20456A','MMT-ADV-Mid-X11.3-R20462A','MMT-ADVMID_ADVHIGH-PL-M20531A','MMT-ADVMID_ADVHIGH-PL-M21081A','MMT-ADVMID_ADVHIGH-PL-M21112A','MMT-ADVMID_ADVHIGH-TL-M20043A','MMT-ADVMID_ADVHIGH-TL-M21191B'];

export const mapFileTableData = {
    defaultColumnOrder: ['display_name', 'map_name', 'version_number', 'previous_version_number', 'map_checksum'],
    columnLabels: {
        display_name: 'fotaone.mu.fileData.displayName',
        previous_version_number: 'fotaone.mu.fileData.preVersion',
        version_number: 'fotaone.mu.fileData.version',
        map_name: 'fotaone.mu.regionData.region.one',
        map_checksum: 'fotaone.mu.fileData.fileChecksumMD5',
    },
    columnDetails: {
        display_name: {},
        previous_version_number: {},
        version_number: {},
        map_name: {},
        fileChecksum: {},
    },
    columns: [
        {
            title: 'fotaone.mu.fileData.displayName',
            key: 'all_names',
            renderer: filenameRender,
            tooltip: filenameInformation,
        },
        {
            title: 'fotaone.mu.regionData.region.one',
            key: 'map_name',
        },
        {
            title: 'fotaone.mu.fileData.version',
            key: 'version_number',
            badge: fileStatusRenderer,
        },
        {
            title: 'fotaone.mu.fileData.preVersion',
            key: 'previous_version_number',
        },
        {
            title: 'fotaone.mu.fileData.fileChecksumMD5',
            key: 'map_checksum',
            renderer: wordShortenerRender,
            tooltip: verifyChecksumInstruction,
        },
    ],
    columnsOrder: ['display_name', 'map_name', 'version_number', 'previous_version_number', 'map_checksum'],
    disabledColumns: [],
    hiddenColumns: [],
};

// used in start single rollout from file list
export const mapRolloutDialogFileTable = [
    {
        title: 'fotaone.mu.fileData.displayName',
        key: 'all_names',
        renderer: filenameRender,
        tooltip: filenameInformation,
    },
    {
        title: 'fotaone.mu.regionData.region.one',
        key: 'map_name',
    },
    {
        title: 'fotaone.mu.fileData.fileSize',
        key: 'map_size',
    },
    {
        title: 'fotaone.mu.fileData.version',
        key: 'version_number',
        badge: fileStatusRenderer,
    },
    {
        title: 'fotaone.mu.fileData.preVersion',
        key: 'previous_version_number',
    },
];
export const groupTableData = {
    defaultColumnOrder: ['name', 'description'],
    columnDetails: {
        name: { width: 200 },
        description: {},
    },
    columns: [
        {
            title: 'fotaone.mu.groupData.groupName',
            key: 'name',
            sortable: true,
        },
        {
            title: 'fotaone.mu.groupData.groupDesc',
            key: 'description',
            sortable: true,
        },
    ],
    columnsOrder: ['name', 'description'],
};
export const fileTableStatusData = {
    defaultColumnOrder: ['displayName', 'updateRegion', 'versionNumber', 'previousVersionNumber', 'status'],
    columnDetails: {
        displayName: { 'word-wrap': 'break-word', width: 130 },
        versionNumber: {},
        previousVersionNumber: {},
        updateRegion: {},
        status: {},
    },
    columns: [
        {
            title: 'fotaone.mu.fileData.displayName',
            key: 'all_names',
            renderer: filenameRender,
            tooltip: filenameInformation,
        },
        {
            title: 'fotaone.mu.regionData.region.one',
            key: 'updateRegion',
            renderer: getRegion,
        },
        {
            title: 'fotaone.mu.fileData.version',
            key: 'versionNumber',
            badge: fileStatusRenderer,
        },
        {
            title: 'fotaone.mu.fileData.preVersion',
            key: 'previousVersionNumber',
        },
        {
            title: 'fotaone.mu.fileData.state.fileState',
            key: 'status',
            statusColor: true,
        },
    ],
    columnsOrder: ['displayName', 'updateRegion', 'versionNumber', 'previousVersionNumber', 'status'],
};
export const eventStatusData = {
    columnsOrder: ['timestamp', 'subject', 'payload'],
    defaultColumnOrder: ['timestamp', 'subject', 'payload'],
    columnDetails: {
        timestamp: { width: 90 },
        subject: { width: 200 },
        payload: { width: '100%' },
    },
    columns: [
        {
            title: 'fotaone.general.timeData.date',
            key: 'timestamp',
            renderer: (time) => formatISO9075(new Date(time)),
        },
        {
            title: 'fotaone.mu.rolloutData.eventSubject',
            key: 'subject',
            renderer: subjectRenderer,
        },
        {
            title: 'fotaone.mu.rolloutData.eventPayload',
            key: 'payload',
            renderer: eventRenderer,
        },
    ],
};
export const mapFileCheckTableData = {
    defaultColumnOrder: ['display_name', 'map_region_enum', 'version_number', 'previous_version_number'],
    columnLabels: {
        display_name: 'fotaone.mu.fileData.displayName',
        map_region_enum: 'fotaone.mu.regionData.region.one',
        previous_version_number: 'fotaone.mu.fileData.preVersion',
        version_number: 'fotaone.mu.fileData.version',
    },
    columnsDetails: {
        display_name: {
            width: 150,
            defaultWidth: 300,
            maxWidth: 300,
        },
        version_number: {
            width: 0,
            defaultWidth: 0,
            maxWidth: 350,
        },
        previous_version_number: {
            width: 180,
            defaultWidth: 180,
            maxWidth: 350,
        },
        map_region_enum: {},
    },
};

export const mapRolloutTableData = {
    defaultColumnOrder: [
        'vehicle.name',
        'vehicle.vin',
        'rollout_packages[0].map_file.display_name',
        'rollout_state.rollout_state_type',
        'rollout_state.timeout_at',
        'created_at',
    ],
    columnsOrder: [
        'vehicle.name',
        'vehicle.vin',
        'rollout_packages[0].map_file.display_name',
        'rollout_state.rollout_state_type',
        'rollout_state.timeout_at',
        'created_at',
    ],
    columnDetails: {
        'vehicle.name': {},
        'vehicle.vin': {},
        'rollout_packages[0].map_file.display_name': {},
        'rollout_state.rollout_state_type': {},
        'rollout_state.timeout_at': {},
        created_at: {},
    },
    columnLabels: {
        'vehicle.name': 'fotaone.general.vehicleData.shortname',
        'vehicle.vin': 'fotaone.general.vehicleData.vinShort',
        'rollout_packages[0].map_file.display_name': 'fotaone.mu.fileData.displayName',
        'rollout_state.rollout_state_type': 'fotaone.mu.rolloutData.rolloutStatus',
        'rollout_state.timeout_at': 'fotaone.mu.rolloutData.lastEvent',
        created_at: 'fotaone.mu.createdAt',
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.shortname',
            key: 'vehicle.name',
            sortable: true,
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vehicle.vin',
            badge: testBadgeRenderer,
            sortable: true,
        },
        {
            title: 'fotaone.mu.fileData.displayName',
            key: 'rollout_packages[0].map_file.display_name',
            sortable: true,
        },
        {
            title: 'fotaone.mu.rolloutData.rolloutStatus',
            key: 'rollout_state.rollout_state_type',
            statusColor: true,
            renderer: (status) => (rolloutStatusMap[status] ? rolloutStatusMap[status] : 'UNKNOWN'),
            badge: rolloutTimeoutBadge,
            sortable: true,
        },
        {
            title: 'fotaone.mu.rolloutData.lastEvent',
            key: 'rollout_state.timeout_at',
            renderer: distanceTimeRenderer,
            tooltip: timestampInformation,
            sortable: true,
        },
        {
            title: 'fotaone.mu.createdAt',
            key: 'created_at',
            renderer: distanceTimeRenderer,
            tooltip: timestampInformation,
            sortable: true,
        },
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
export const rolloutLabels = [
    {
        icon: '1',
        label: 'fotaone.mu.fileData.file.many',
    },
    {
        icon: '2',
        label: 'fotaone.general.vehicleData.vehicle.one',
    },
    {
        icon: <i className='rioglyph rioglyph-ok' />,
        label: 'fotaone.general.preview',
    },
];
